import React,  { Component, formData }  from 'react'
import { stringify } from 'qs'
import { serialize,deserialize } from 'dom-form-serializer'


import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    name: 'JoinCampaign',
    subject: '', // optional subject of the notification email
    action: '',
    honeypot: 'confirm',
    successMessage: 'Thank you for joining our cause to let Candace represent the people CD24',
    errorMessage:
      'There was an issue with your form.  Email info@candacefor24.com'
  }

  state = {
    alert: '',
    disabled: false
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return
    //const form = e.target
    const ogform = new FormData(e.target)
    const nestedParams = {"firstName": ogform.get('firstname'), "lastName":ogform.get('lastname'), "emails": [ { "email": ogform.get('email') } ], "addresses": [ { "zipOrPostalCode": ogform.get('zipOrPostalCode')} ] }
    this.setState({ disabled: true })
    fetch('/van/people/findOrCreate', {
      crossDomain:true,
      method: 'POST',
      //redirect: 'manual',
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      },
      body: JSON.stringify(nestedParams)
    })
    .then(() => {
      //e.reset()
      this.setState({
        alert: this.props.successMessage,
        disabled: false
       })
      })
    .catch(err => {
        // console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, subject, action, honeypot } = this.props

    return (
      <form
        className="Form"
        name={name}
        action={action}
        onSubmit={this.handleSubmit}
      >
        {this.state.alert && (
          <div className="Form--Alert">{this.state.alert}</div>
        )}
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="text"
              placeholder="First name"
              name="firstname"
              required
            />
          </label>
          <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="text"
              placeholder="Last name"
              name="lastname"
              required
            />
          </label>
        <label className="Form--Label">
          <input
            className="Form--Input Form--InputText"
            type="email"
            placeholder="Email"
            name="email"
            required
          />
        </label>
        <label className="Form--Label">
            <input
              className="Form--Input Form--InputText"
              type="text"
              placeholder="Zipcode"
              name="zipOrPostalCode"
              required
            />
          </label>
        {!!subject && <input type="hidden" name="subject" value={subject} />}
        <input
          className="Button Form--SubmitButton float"
          type="submit"
          value="Join Our Cause"
          disabled={this.state.disabled}
        />
      </form>
    )
  }
}

export default Form